<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <template v-if="isResetPasswordWithTokenSuccess">
          <div class="text-center">
            <feather-icon
              icon="CheckCircleIcon"
              size="80"
              class="text-success"
            />
            <h3 class="my-2">
              เปลี่ยนรหัสผ่านใหม่สำเร็จ
            </h3>
            <h6 class="text-secondary mb-2">
              เข้าสู่ระบบด้วยรหัสผ่านชุดใหม่ที่คุณเพิ่งเปลี่ยน
            </h6>
          </div>
        </template>
        <template v-else>
          <b-card-title class="mb-1">
            เปลี่ยนรหัสผ่านใหม่ 🔒
          </b-card-title>

          <!-- form -->
          <validation-observer ref="resetPasswordForm">
            <b-form
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- password -->
              <b-form-group
                label="รหัสผ่านใหม่"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="resetPasswordData.password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="ยืนยันรหัสผ่าน"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="confirmPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-overlay :show="isResettingPasswordWithToken">
                <b-button
                  variant="primary"
                  block
                  type="submit"
                >
                  บันทึก
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </template>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> กลับไปหน้าล๊อกอิน
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      resetPasswordData: {
        token: '',
        password: '',
      },
      confirmPassword: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    ...mapState({
      isResettingPasswordWithToken: (state) =>
        state.user.isResettingPasswordWithToken,
      isResetPasswordWithTokenSuccess: (state) =>
        state.user.isResetPasswordWithTokenSuccess,
    }),
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    token() {
      const { params } = this.$route
      return params?.token || ''
    },
  },
  watch: {
    isResetPasswordWithTokenSuccess(val) {
      if (val) {
        console.log('isResetPasswordWithTokenSuccess')
      }
    },
  },
  methods: {
    ...mapActions(['resetPasswordWithToken']),
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.resetPasswordForm.validate().then((success) => {
        if (success) {
          this.resetPasswordData.token = this.token
          this.resetPasswordWithToken({
            token: this.token,
            data: this.resetPasswordData,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
